import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { ContainerItem, TextBlock } from '@headout/aer';

import { useToolbarStyles, useStyles } from './common/tableUtils';
import { AVAILABILITY, UNLIMITED_SEATS } from '../constants';

export function ReviewTable({ rows }) {
	const classes = useStyles();
	return (
		<Paper>
			<TableContainer>
				<Table
					className={classes.table}
					aria-labelledby='tableTitle'
					size={'medium'}
					aria-label='enhanced table'
				>
					<EnhancedTableHead
						classes={classes}
						order={''}
						orderBy={''}
						onRequestSort={''}
						rowCount={rows.length}
					/>
					<TableBody>
						{rows.map((row, index) => {
							const isUnlimitedInventory =
								row.current.availability === AVAILABILITY.UNLIMITED ||
								row.current.remaining === UNLIMITED_SEATS;

							const seatsDiffBlock = (
								<div>
									<TextBlock
										color='rhapsody'
										family='content'
										style={{ marginBottom: 0, width: 100, display: 'inline' }}
										weight='bold'
										tailwind='text-center w-full'
									>
										{row.initial.remaining}
									</TextBlock>
									->
									<TextBlock
										color='teal'
										family='content'
										style={{ marginBottom: 0, width: 100, display: 'inline' }}
										weight='bold'
										tailwind='text-center w-full'
									>
										{row.current.remaining}
									</TextBlock>
								</div>
							);

							return (
								<TableRow hover tabIndex={-1} key={index}>
									<TableCell align='right'>{row.initial.startDate}</TableCell>
									<TableCell align='right'>{row.initial.startTime}</TableCell>
									<TableCell align='right'>{row.initial.tourGroupName}</TableCell>
									<TableCell align='right'>{row.initial.tourName}</TableCell>
									<TableCell align='right'>
										{row.initial.closed === row.current.closed ? (
											row.current.closed ? (
												'Closed'
											) : (
												'Open'
											)
										) : (
											<div>
												<TextBlock
													color='rhapsody'
													family='content'
													style={{ marginBottom: 0, width: 100, display: 'inline' }}
													weight='bold'
													tailwind='text-center w-full'
												>
													{row.initial.closed ? 'Closed' : 'Open'}
												</TextBlock>
												⇾
												<TextBlock
													color='teal'
													family='content'
													style={{ marginBottom: 0, width: 100, display: 'inline' }}
													weight='bold'
													tailwind='text-center w-full'
												>
													{row.current.closed ? 'Closed' : 'Open'}
												</TextBlock>
											</div>
										)}
									</TableCell>
									<TableCell align='right'>
										{isUnlimitedInventory
											? 'ULTD.'
											: row.current.remaining === row.initial.remaining
											? row.current.remaining
											: seatsDiffBlock}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}

/*
		Header and toolbar components for table
*/

const headCells = [
	{ id: 'startDate', numeric: true, disablePadding: false, label: 'Date' },
	{ id: 'startTime', numeric: true, disablePadding: false, label: 'Time' },
	{
		id: 'tourGroupName',
		numeric: false,
		disablePadding: false,
		label: 'Experience Name as on Headout',
	},
	{
		id: 'tourName',
		numeric: true,
		disablePadding: false,
		label: 'Options',
	},
	{ id: 'closed', numeric: true, disablePadding: false, label: 'Status Change' },
	{
		id: 'remaining',
		numeric: true,
		disablePadding: false,
		label: 'Seats',
	},
];
function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'right'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const { numSelected } = props;
	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color='inherit' variant='subtitle1'>
					{numSelected} tours Selected
				</Typography>
			) : (
				<Typography className={classes.title} variant='h6' id='tableTitle'>
					Inventory List
				</Typography>
			)}
		</Toolbar>
	);
};
EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};
