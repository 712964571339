import React, { Component } from 'react';
import { getBackendAPI, postData } from '../utils';
import {
	Container,
	ContainerItem,
	Input,
	Button,
	TextBlock,
} from '@headout/aer';
import { withRouter } from 'react-router';
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: { error: null, value: '' },
			password: { error: null, value: '' },
		};
	}
	setEmail = (e) => {
		this.setState({ email: { value: e.target.value } });
	};

	setPassword = (e) => {
		this.setState({
			password: {
				value: e.target.value,
			},
		});
	};

	onSubmit = () => {
		const { email, password } = this.state;
		const loginUrl = `${getBackendAPI().CALIPSO}/v3/supply-partner/login`;
		postData(loginUrl, { email: email.value, password: password.value })
			.then((response) => {
				this.handleResponse(response);
			})
			.catch((e) => {
				alert(e.toString());
			});
	};
	handleResponse(response) {
		const token = response?.base64EncodedValue;
		if (token) {
			localStorage.setItem('isUserLoggedIn', true);
			this.setTokenAndRedirect();
		} else {
			alert('Invalid credentials');
		}
	}
	setTokenAndRedirect() {
		this.props.setUserToLoggedIn();
		this.props.history.push('/dashboard');
	}

	loginScreen = () => {
		const { email, password } = this.state;

		return (
			<Container
				color='black'
				direction='col'
				justify='center'
				type='flex'
				weight='normal'
				tailwind={'login-container '}
			>
				<ContainerItem tailwind='mt-2 mb-2 items-center'>
					<img
						src='https://cdn-imgix-open.headout.com/logo/svg/headout_logo_new.svg?w=300&h=50&fit=fill'
						width='40%'
					/>

				</ContainerItem>
				<ContainerItem tailwind='mb-3'>Supply Partner Extranet (Beta)</ContainerItem>
				<ContainerItem tailwind=' mb-3'>
					<Input
						color='teal'
						handleReturnFun={this.onSubmit}
						onChange={this.setEmail}
						isError={false}
						onIconClick={null}
						value={email.value}
						placeholder='Enter email'
						size='full'
					/>
				</ContainerItem>
				<ContainerItem tailwind='mb-4'>
					<Input
						color='teal'
						onChange={this.setPassword}
						value={password.value}
						isError={false}
						onIconClick={null}
						placeholder='Enter Password'
						handleReturnFun={this.onSubmit}
						size='full'
						type='password'
					/>
				</ContainerItem>
				<ContainerItem tailwind='mb-2'>
					<Button
						bgcolor='rhapsody'
						handleClick={this.onSubmit}
						color='white'
						width='full'
					>
						<TextBlock
							color='white'
							family='content'
							style={{ marginBottom: 0 }}
							weight='normal'
						>
							Sign in
						</TextBlock>
					</Button>
				</ContainerItem>
			</Container>
		);
	};
	render() {
		return this.loginScreen();
	}
}
export default withRouter(Login);
