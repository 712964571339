import {
	AVAILABILITY,
	BACKEND_HOSTS,
	FRONTEND_HOST,
	UNLIMITED_SEATS,
} from './constants';

export const getBackendAPI = () => {
	switch (window.location.host) {
		case 'supply-extranet.stage-headout.com':
			return BACKEND_HOSTS.STAGE;
		case 'supply-extranet.test-headout.com':
			return BACKEND_HOSTS.TEST;
		case 'supply-extranet.headout.com':
			return BACKEND_HOSTS.PROD;
		default:
			return BACKEND_HOSTS.DEV;
	}
};

export const getFrontendHost = () => {
	switch (window.location.host) {
		case 'supply-extranet.stage-headout.com':
			return FRONTEND_HOST.STAGE.DEIMOS;
		case 'supply-extranet.test-headout.com':
			return FRONTEND_HOST.TEST.DEIMOS;
		case 'supply-extranet.headout.com':
			return FRONTEND_HOST.PROD.DEIMOS;
		default:
			return FRONTEND_HOST.DEV.DEIMOS;
	}
};

export async function postData(url = '', data = {}) {
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		credentials: 'include',
		body: JSON.stringify(data),
	});

	if (response.status !== 200) {
		const responseBody = await response.json();
		const { error } = responseBody;
		throw Error(error.message || `${error.code} - ${error.title}`);
	}

	return response.json();
}

export async function putData(url = '', data = {}) {
	// Default options are marked with *
	const response = await fetch(url, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		credentials: 'include',
		body: JSON.stringify(data), // body data type must match "Content-Type" header
	});

	if (response.status !== 200) {
		const responseBody = await response.json();
		const { error } = responseBody;
		throw Error(error.message || `${error.code} - ${error.title}`);
	}

	return response.status; // parses JSON response into native JavaScript objects
}

export async function getData(url = '') {
	// Default options are marked with *
	const response = await fetch(url, {
		credentials: 'include',
	});

	if (response.status !== 200) {
		const responseBody = await response.json();
		const { error } = responseBody;
		throw Error(error.message || `${error.code} - ${error.title}`);
	}

	return response.json(); // parses JSON response into native JavaScript objects
}

export function parseQuery(queryString) {
	const query = {};
	const pairs = (queryString[0] === '?'
		? queryString.substr(1)
		: queryString
	).split('&');
	for (let i = 0; i < pairs.length; i++) {
		const pair = pairs[i].split('=');
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
	}
	return query;
}

export async function updateInventoryOnServer(inventories) {
	return putData(
		`${getBackendAPI().ARIES}/v2/inventories/current-vendor`,
		inventories,
	);
}

export const checkInventoryIsModified = (changedInventory, inventoryItem) =>
	changedInventory.filter((inventory) => {
		return (
			inventoryItem.tourId === inventory.tourId &&
			inventoryItem.startDate === inventory.startDate &&
			inventoryItem.startTime === inventory.startTime
		);
	}).length > 0;

/*
	Modifies request before sending to server. Does few things
	set seats = 9999 when closing inventory
	set availability = UNLIMITED if seats = 9999 while opening inventory
 */
export const parseInventoryBeforeSubmitting = (inventoryList) => {
	return inventoryList.map((inventory) => {
		if (inventory.availability === AVAILABILITY.UNLIMITED) {
			inventory.remaining = UNLIMITED_SEATS;
		}

		const shouldOpenInventory = !inventory.closed;

		// Set inventory status on closed item.
		if (shouldOpenInventory) {
			if (inventory.remaining === UNLIMITED_SEATS) {
				inventory.availability = AVAILABILITY.UNLIMITED;
			} else {
				inventory.availability = AVAILABILITY.LIMITED;
			}
		} else {
			inventory.availability = AVAILABILITY.CLOSED;
		}

		return { ...inventory };
	});
};
