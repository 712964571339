import React from 'react';
import './App.css';
import '@headout/aer/other/output.css';

import Login from './screens/Login';
import 'antd/dist/antd.css';
import Dashboard from './screens/Dashboard';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import { getBackendAPI, getData } from './utils';
import EditTours from './screens/EditInventory';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedIn: false,
			loading: true,
		};
	}

	componentDidMount() {
		this.isUserLoggedIn();
	}

	// This is called on at app mount every time.
	// For long term session check on every route mount
	async isUserLoggedIn() {
		const pingUrl = `${getBackendAPI().CALIPSO}/v3/supply-partner/ping`;
		try {
			const response = await getData(pingUrl);
			if (response.success) {
				this.setState({ loggedIn: true });
			}
		} catch (e) {
			alert(`Some error Occurred :- ${e}`);
		}
		this.setState({ loading: false });
	}

	// State is maintained at app level.
	// This need to be passed on on to child component to make loggedIn true.
	// Use redux to eliminate this.
	setUserToLoggedIn = () => {
		this.setState({ loggedIn: true });
	};

	render() {
		const { loggedIn, loading } = this.state;

		// Wrapper component to render based on user login.
		const PrivateRoute = ({ path, children }) => {
			return (
				<Route path={path}>
					{loggedIn ? children : <Redirect to={{ pathname: '/' }} />}
				</Route>
			);
		};

		if (loading) return <div>Loading</div>;

		return (
			<div>
				<Router>
					<Switch>
						<Route exact path='/'>
							{loggedIn ? (
								<Redirect to={{ pathname: '/dashboard' }} />
							) : (
								<Login setUserToLoggedIn={this.setUserToLoggedIn} />
							)}
						</Route>
						<PrivateRoute path='/dashboard'>
							<Dashboard />
						</PrivateRoute>
						<PrivateRoute path='/edit'>
							<EditTours />
						</PrivateRoute>
					</Switch>
				</Router>
			</div>
		);
	}
}

export default App;
