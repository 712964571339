import {
	LOAD_INVENTORY,
	CLEAR_INVENTORY,
	REVERT_CLOSED_STATUS,
	MARK_INVENTORY_CLOSED_STATUS,
	UPDATE_INVENTORY,
} from './actions/actions';
import { AVAILABILITY } from '../constants';

/**
 * We don't consider closed for maintaining closed state.
 * Reasons :-
 * 1.) It's not absolute truthful state, inventory can be close==false and availability can be closed.
 * 2.) It hard closed inventory closed: availability === AVAILABILITY.CLOSED to
 * maintain state and change it while uploading inventory.
 *
 * Solution :- While adding inventory we use
 *
 * @param tourId
 * @param data
 * @returns {function(*): *}
 */
export const addInventory = (tourId, data) => (dispatch) => {
	let { list: inventoryList } = data;
	inventoryList = inventoryList.map((list_item) => {
		const {
			tourId,
			tourGroupName,
			tourName,
			remaining,
			availability,
			date,
			time,
			invId,
			discount,
		} = list_item;
		return {
			tourId,
			remaining,
			startDate: date,
			startTime: time,
			availability,
			discount,
			closed: availability === AVAILABILITY.CLOSED,
			tourGroupName,
			tourName,
			invId,
		};
	});
	return dispatch({
		type: LOAD_INVENTORY,
		payload: inventoryList,
	});
};
export const clearInventory = () => (dispatch) => {
	return dispatch({
		type: CLEAR_INVENTORY,
	});
};
export const updateInventory = (index, count) => (dispatch) => {
	return dispatch({
		type: UPDATE_INVENTORY,
		index,
		count,
	});
};

export const revertInventoryClosedStatus = (index) => (dispatch) => {
	return dispatch({
		type: REVERT_CLOSED_STATUS,
		index,
	});
};

export const setInventoryClosedStatus = (index, status) => (dispatch) => {
	return dispatch({
		type: MARK_INVENTORY_CLOSED_STATUS,
		index,
		status,
	});
};
