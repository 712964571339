import { compose, createStore } from 'redux';
import rootReducer from './redux/reducers/rootreducer';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

export const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(thunk),
		window.__REDUX_DEVTOOLS_EXTENSION__
			? window.__REDUX_DEVTOOLS_EXTENSION__()
			: (noop) => noop,
	),
);
