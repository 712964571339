import {
	LOAD_INVENTORY,
	CLEAR_INVENTORY,
	REVERT_CLOSED_STATUS,
	UPDATE_INVENTORY,
	MARK_INVENTORY_CLOSED_STATUS,
} from '../actions/actions';
const initialState = [];
export default function (state = initialState, action) {
	switch (action.type) {
		case LOAD_INVENTORY:
			return [...state, ...action.payload];
		case CLEAR_INVENTORY:
			return [];
		case UPDATE_INVENTORY:
			return [
				...state.slice(0, action.index),
				{
					...state[action.index],
					remaining: action.count,
				},
				...state.slice(action.index + 1),
			];
		case REVERT_CLOSED_STATUS:
			return [
				...state.slice(0, action.index),
				{
					...state[action.index],
					closed: !state[action.index].closed,
				},
				...state.slice(action.index + 1),
			];
		case MARK_INVENTORY_CLOSED_STATUS:
			return [
				...state.slice(0, action.index),
				{
					...state[action.index],
					closed: action.status,
				},
				...state.slice(action.index + 1),
			];
		default:
			return state;
	}
}
