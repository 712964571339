/**
 * We're using material UI table here for extended functionality purposed.
 * Things like - Pagination, sorting, selection, no of items in pages
 * are supported out of the box.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {
	Button,
	ContainerItem,
	TextBlock,
	Input,
	Container,
} from '@headout/aer';
import { useHistory } from 'react-router-dom';
import {
	useToolbarStyles,
	useStyles,
	getComparator,
	stableSort,
} from './common/tableUtils';

const externalLink = (
	<svg
		enable-background='new 0 0 511.626 511.627'
		version='1.1'
		viewBox='0 0 511.63 511.63'
		height={15}
		width={15}
		style={{ display: 'inline' }}
	>
		<path d='m392.86 292.35h-18.274c-2.669 0-4.859 0.855-6.563 2.573-1.718 1.708-2.573 3.897-2.573 6.563v91.361c0 12.563-4.47 23.315-13.415 32.262-8.945 8.945-19.701 13.414-32.264 13.414h-237.54c-12.562 0-23.317-4.469-32.264-13.414-8.945-8.946-13.417-19.698-13.417-32.262v-237.54c0-12.562 4.471-23.313 13.417-32.259 8.947-8.947 19.702-13.418 32.264-13.418h200.99c2.669 0 4.859-0.859 6.57-2.57 1.711-1.713 2.566-3.9 2.566-6.567v-18.275c0-2.662-0.855-4.853-2.566-6.563-1.711-1.713-3.901-2.568-6.57-2.568h-200.99c-22.648 0-42.016 8.042-58.102 24.125-16.08 16.082-24.122 35.45-24.122 58.098v237.54c0 22.647 8.042 42.018 24.123 58.095 16.086 16.084 35.454 24.13 58.102 24.13h237.54c22.647 0 42.017-8.046 58.101-24.13 16.085-16.077 24.127-35.447 24.127-58.095v-91.358c0-2.669-0.856-4.859-2.574-6.57-1.713-1.718-3.903-2.573-6.565-2.573z' />
		<path d='m506.2 41.971c-3.617-3.617-7.905-5.424-12.85-5.424h-146.18c-4.948 0-9.233 1.807-12.847 5.424-3.617 3.615-5.428 7.898-5.428 12.847s1.811 9.233 5.428 12.85l50.247 50.248-186.15 186.15c-1.906 1.903-2.856 4.093-2.856 6.563 0 2.479 0.953 4.668 2.856 6.571l32.548 32.544c1.903 1.903 4.093 2.852 6.567 2.852s4.665-0.948 6.567-2.852l186.15-186.15 50.251 50.248c3.614 3.617 7.898 5.426 12.847 5.426s9.233-1.809 12.851-5.426c3.617-3.616 5.424-7.898 5.424-12.847v-146.18c-1e-3 -4.952-1.814-9.232-5.428-12.847z' />
	</svg>
);

export default function ToursTable({ rows = [] }) {
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('name');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [query, setQuery] = useState(null);
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const listOfAllTourIds = rows.map((n) => n.tourId);
			setSelected(listOfAllTourIds);
			return;
		}
		setSelected([]);
	};
	const handleClick = (event, tourId) => {
		const selectedIndex = selected.indexOf(tourId);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, tourId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const isSelected = (name) => selected.indexOf(name) !== -1;
	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const basedOnQuery = (tour) => {
		const { nameInVendorSite, tourGroupName, tourId, tourName } = tour;
		if (!query) return true;
		return (
			nameInVendorSite.toLowerCase().includes(query.toLowerCase()) ||
			tourGroupName.toLowerCase().includes(query.toLowerCase()) ||
			tourName.toLowerCase().includes(query.toLowerCase()) ||
			tourId.toString().toLowerCase().includes(query.toLowerCase())
		);
	};
	return (
		<div className={classes.root}>
			<ContainerItem tailwind='mb-2 table-container'>
				<Paper className={classes.paper}>
					<EnhancedTableToolbar
						query={query}
						setQuery={setQuery}
						numSelected={selected.length}
						selected={selected}
					/>
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby='tableTitle'
							size={dense ? 'small' : 'medium'}
							aria-label='enhanced table'
						>
							<EnhancedTableHead
								classes={classes}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>
							<TableBody>
								{stableSort(rows, getComparator(order, orderBy))
									.filter(basedOnQuery)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isItemSelected = isSelected(row.tourId);
										const labelId = `enhanced-table-checkbox-${index}`;
										return (
											<TableRow
												hover
												onClick={(event) => handleClick(event, row.tourId)}
												role='checkbox'
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={index}
												selected={isItemSelected}
											>
												<TableCell padding='checkbox'>
													<Checkbox
														checked={isItemSelected}
														inputProps={{ 'aria-labelledby': labelId }}
													/>
												</TableCell>
												<TableCell component='th' id={labelId} scope='row' padding='none'>
													{row.nameInVendorSite}
												</TableCell>
												<TableCell>{row.tourGroupName}</TableCell>
												<TableCell>{row.tourName}</TableCell>
												<TableCell>{row.tourId}</TableCell>
												<TableCell>
													<a
														href={row.link}
														target='_blank'
														onClick={(event) => event.stopPropagation()}
													>
														Headout Experience Link {externalLink}
													</a>
												</TableCell>
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component='div'
						count={rows.length}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage={'Items per page'}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</ContainerItem>
		</div>
	);
}

/*
		Header and toolbar components for table
*/

const headCells = [
	{
		id: 'nameInVendorSite',
		numeric: false,
		disablePadding: true,
		label: 'Supply Partner Reference',
	},
	{
		id: 'tourGroupName',
		numeric: false,
		disablePadding: false,
		label: 'Experience Name as on Headout',
	},
	{
		id: 'tourName',
		numeric: false,
		disablePadding: false,
		label: 'Options',
	},
	{ id: 'tourId', numeric: true, disablePadding: false, label: 'ID' },
	{ id: 'link', numeric: true, disablePadding: false, label: 'Headout Experience Link' },
];

function EnhancedTableHead(props) {
	const {
		classes,
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow>
				<TableCell padding='checkbox'>
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const { selected } = props;
	const classes = useToolbarStyles();
	const { numSelected, query, setQuery } = props;

	const history = useHistory();

	const onEditClick = () => {
		if (selected.length === 0) {
			alert('No tours selected, Please select tours you want to edit');
			return;
		}
		history.push({
			pathname: '/edit',
			search: `?tour_ids=${selected.join(',')}`,
		});
	};

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			<Container
				tailwind='mb-2 mt-2 w-full '
				justify={'between'}
				align={'center'}
				id='dashboard-header'
			>
				<ContainerItem tailwind='flex-' id='dashboard-header-left-area'>
					<ContainerItem>
						<Input
							color='teal'
							handleReturnFun={null}
							onChange={(e) => {
								setQuery(e.target.value);
							}}
							isError={false}
							onIconClick={null}
							placeholder='Search Experiences'
							value={query}
						/>
					</ContainerItem>
					<ContainerItem tailwind={'ml-2 items-center'} id='tour-selected-info'>
						{selected.length === 0 ? '' : `${selected.length} tours selected`}
					</ContainerItem>
				</ContainerItem>
				<ContainerItem tailwind='text-2' id='dashboard-header-right-area'>
					<Button
						handleClick={onEditClick}
						style={{
							marginBottom: 0,
							maxWidth: 380,
							opacity: selected.length > 0 ? 1 : 0.9,
							cursor: selected.length === 0 ? 'not-allowed' : 'pointer',
						}}
						tailwind='w-full ml-2 pl-6 pr-6'
					>
						<TextBlock
							color='white'
							family='content'
							style={{ marginBottom: 0, width: 100 }}
							weight='bold'
							tailwind='text-center w-full leading-none'
						>
							Manage Inventory
						</TextBlock>
					</Button>
				</ContainerItem>
			</Container>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};
