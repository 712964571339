import { LOAD_INVENTORY, CLEAR_INVENTORY } from '../actions/actions';
const initialState = [];
export default function (state = initialState, action) {
	switch (action.type) {
		case LOAD_INVENTORY:
			return [...state, ...action.payload];
		case CLEAR_INVENTORY:
			return [];
		default:
			return state;
	}
}
