import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
	Container,
	ContainerItem,
	TextBlock,
	DropDown,
	Button,
} from '@headout/aer';
import InventoryTable from '../components/InventoryTable';
import {
	getBackendAPI,
	getData,
	parseInventoryBeforeSubmitting,
	parseQuery,
} from '../utils';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { addInventory, clearInventory } from '../redux/thunks';
import { getTimeSlots, getTourGroupName, getTourName, getUpdatedInventory } from '../redux/stateUtils';
import { withRouter } from 'react-router-dom';
import { INVENTORY_EDIT_STATE } from '../constants';
import { updateInventoryOnServer } from '../utils';
import { ReviewTable } from '../components/ReviewTable';

import moment from 'moment';
import { LogoBar } from '../components/LogoBar';


const backIcon = (
<svg x="0px" y="0px"
viewBox="0 0 492 492" height={16} width={16} >
	<g>
	<g>
	<path d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
C492,219.198,479.172,207.418,464.344,207.418z" fill={'#585858'}/>
</g>
	</g>
</svg>

)
class EditInventory extends Component {
	constructor(props) {
		super(props);
		const queryParam = parseQuery(this.props.location.search);
		const tourIds = queryParam.tour_ids.split(',');

		const initialStartDate = moment();
		const initialEndDate = moment().add('15', 'days');

		this.state = {
			tourIds,
			startDate: initialStartDate,
			endDate: initialEndDate,
			focusedInput: null,
			selectedTime: { label: 'Select Time Slot', value: null },
			selectedTourGroup: { label: 'Select Experience Name', value: null },
			selectedTour: { label: 'Select Option', value: null },
			selectedClosedStatus: { label: 'Select Status', value: null },
			loading: false,
			hasSearched: false,
			state: INVENTORY_EDIT_STATE.EDIT,
			disableApproveButton: false,
		};
	}

	componentDidMount() {
		const { clearInventory } = this.props;
		clearInventory();
		this.fetchInventoryDataFromServer();
	}

	fetchInventoryDataFromServer() {
		let { tourIds, startDate, endDate } = this.state;
		const { addInventory, clearInventory } = this.props;
		if (!startDate && !endDate) {
			alert('Please select start date');
			return;
		}
		if(!endDate){
			endDate = startDate;
			this.setState({endDate: startDate})
		}

		if (endDate.diff(startDate, 'days') > 30) {
			alert(
				'Maximum allowed duration is 30 days. Please select shorter duration.',
			);
			return;
		}

		clearInventory();

		this.setState({ loading: true, hasSearched: true, 			selectedTime: { label: 'Select Time Slot', value: null },
			selectedTourGroup: { label: 'Select Experience Name', value: null },
			selectedTour: { label: 'Select Option', value: null },
			selectedClosedStatus: { label: 'Select Status', value: null }, });

		const apiPromises = tourIds.map((tourId) => {
			const inventoryEndpoint = `${
				getBackendAPI().ARIES
			}/inventory/getInventoryWithinDateRange/current-user?fromDate=${startDate.format(
				'YYYY-MM-DD',
			)}&toDate=${endDate.format(
				'YYYY-MM-DD',
			)}&tourId=${tourId}&pageInfo.maxListCot=10000`;

			return getData(inventoryEndpoint)
				.then((data) => {
					addInventory(tourId, data);
				})
				.catch((e) => {
					alert(`Some error occurred while fetching inventory ${e}`);
				});
		});

		Promise.all(apiPromises).then((res) => {
			this.setState({ loading: false });
		});
	}

	showDelta() {
		const { inventoryChange } = this.props;
		const { disableApproveButton } = this.state;

		const updateInventory = () => {
			const { history } = this.props;
			const parsedInventory = parseInventoryBeforeSubmitting(
				inventoryChange.newInventory,
			);
			this.setState({ disableApproveButton: true });

			updateInventoryOnServer(parsedInventory)
				.then(() => {
					alert('Inventory has been updated');
					history.push('/dashboard');
				})
				.catch(() => {
					this.setState({ disableApproveButton: false });
					alert('Some error occurred while updating inventory');
				});
		};

		const goToInventoryEditPage = ()=>{
			this.setState({ state: INVENTORY_EDIT_STATE.EDIT });
		}

		return (
			<div className={'items-center '}>
				<ContainerItem style={{ marginBottom: 40 }}>
					<Container onClick={goToInventoryEditPage} tailwind={'items-center cursor-pointer'}>
						{backIcon}
					</Container>
					<TextBlock
						color='black'
						family='content'
						size={2}
						weight='bold'
						tailwind='w-full mobile-padding ml-2'
					>
						Please confirm these changes
					</TextBlock>
				</ContainerItem>
				<ContainerItem tailwind='mb-2 table-container'>
					<ReviewTable rows={inventoryChange.inventoryDiff} />
				</ContainerItem>
				<ContainerItem tailwind='justify-end mb-4'>
					<Button
						style={{
							marginBottom: 0,
							maxWidth: 380,
							cursor: disableApproveButton ? 'not-allowed' : 'pointer',
						}}
						handleClick={updateInventory}
						disabled={disableApproveButton}
					>
						<TextBlock
							color='white'
							family='content'
							style={{ marginBottom: 0, width: 100 }}
							weight='bold'
							tailwind='text-center w-full'
						>
							Approve Changes
						</TextBlock>
					</Button>
				</ContainerItem>
			</div>
		);
	}

	showInventory() {
		const { loading, selectedTime, hasSearched, selectedTourGroup, selectedTour, selectedClosedStatus} = this.state;

		if (loading) {
			return (
				<Container tailwind='mt-2' align='center' justify='center'>
					Loading
				</Container>
			);
		}

		if (!hasSearched) return null;

		return (
			<div>
				<ContainerItem tailwind='mb-2 table-container'>
					<InventoryTable selectedTime={selectedTime.value}
																					selectedTourGroup={selectedTourGroup.value}
																					selectedTour={selectedTour.value}
																					selectedClosedStatus={selectedClosedStatus.value}
					/>
				</ContainerItem>
			</div>
		);
	}
	filterSection() {
		return (
			<React.Fragment>
				<ContainerItem style={{ marginBottom: 40 }}>
					<TextBlock
						color='black'
						family='content'
						size={3}
						weight='bold'
						tailwind='w-full mobile-padding'
					>
						Select date and time to manage inventory
					</TextBlock>
				</ContainerItem>
				<ContainerItem tailwind='items-center mb-4'>
					<Container
						tailwind={'w-full tour-filter-selection'}
						align='center'


					>
						<ContainerItem width='5/12' tailwind={'mr-1'} id='date-selection'>
							<DateRangePicker
								startDate={this.state.startDate}
								startDateId='your_unique_start_date_id'
								endDate={this.state.endDate}
								endDateId='your_unique_end_date_id'
								onDatesChange={({ startDate, endDate }) =>
									this.setState({
										startDate,
										endDate,
									})
								}
								focusedInput={this.state.focusedInput}
								onFocusChange={(focusedInput) => this.setState({ focusedInput })}
							/>
						</ContainerItem>

						<ContainerItem width='3/12' id='submit-button' tailwind={'mr-1'}>
							<Button
								bgcolor='rhapsody'
								color='white'
								tailwind={'w-full'}
								handleClick={this.fetchInventoryDataFromServer.bind(this)}
							>
								<TextBlock color='white' family='content' size={2} weight='normal'>
									Search Inventory
								</TextBlock>
							</Button>
						</ContainerItem>
						<ContainerItem width='2/12' tailwind='header-control-selection' >
							<Button
								style={{ marginBottom: 0}}
								tailwind='w-full'
								handleClick={() => {
									const { inventoryChange } = this.props;
									if (inventoryChange.inventoryDiff.length === 0) {
										alert(
											"Please edit inventories before changing it. Can't perform this action.",
										);
										return;
									}
									this.setState({ state: INVENTORY_EDIT_STATE.REVIEW });
								}}
							>
								<TextBlock
									color='white'
									family='content'
									weight='bold'
									tailwind='text-center w-full'
								>
									Save Changes
								</TextBlock>
							</Button>
						</ContainerItem>

					</Container>
				</ContainerItem>
				<ContainerItem tailwind='items-center mb-4'>
					<Container
						tailwind={'w-full tour-filter-selection'}
						align='center'

					>

						<ContainerItem width='2/12'  tailwind={'mr-1 header-control-selection'}>
							<DropDown
								iconSrc={'/assets/chevron_down.svg'}
								color='teal'
								isSearchable={false}
								options={[
									{ label: 'All Slots', value: null },
									...this.props.timeSlots,
								]}
								handleChange={(object) => this.setState({ selectedTime: object })}
								value={this.state.selectedTime}
								size={2}
							/>
						</ContainerItem>
						<ContainerItem width='3/12' tailwind={'mr-1 header-control-selection'}>
							<DropDown
								iconSrc={'/assets/chevron_down.svg'}
								color='teal'
								isSearchable={false}
								options={[
									{ label: 'All Products', value: null },
									...this.props.tourGroupNames,
								]}
								handleChange={(object) => this.setState({ selectedTourGroup: object })}
								value={this.state.selectedTourGroup}
								size={2}
							/>
						</ContainerItem>

						<ContainerItem width='3/12'  tailwind={'mr-1 header-control-selection'}>
							<DropDown
								iconSrc={'/assets/chevron_down.svg'}
								color='teal'
								isSearchable={false}
								options={[
									{ label: 'All Options', value: null },
									...this.props.tourNames,
								]}
								handleChange={(object) => this.setState({ selectedTour: object })}
								value={this.state.selectedTour}
								placeholder='Tour Name'
								size={2}
							/>
						</ContainerItem>

						<ContainerItem width='2/12' tailwind={'mr-1 header-control-selection'}>
							<DropDown
								iconSrc={'/assets/chevron_down.svg'}
								color='teal'
								isSearchable={false}
								options={[
									{ label: 'All Status', value: null },
									{ label: 'Open', value: false },
									{ label: 'Closed', value: true },
								]}
								handleChange={(object) => this.setState({ selectedClosedStatus: object })}
								value={this.state.selectedClosedStatus}
								placeholder='Select Status'
								size={2}
							/>
						</ContainerItem>

					</Container>
				</ContainerItem>
			</React.Fragment>
		);
	}
	render() {
		const { state } = this.state;
		return (
			<Container
				color='black'
				direction='col'
				type='flex'
				weight='normal'
				tailwind={'content-container '}
			>
				<LogoBar />
				{state === INVENTORY_EDIT_STATE.EDIT ? this.filterSection() : null}
				{state === INVENTORY_EDIT_STATE.EDIT
					? this.showInventory()
					: this.showDelta()}
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	inventory: state.inventory,
	timeSlots: getTimeSlots(state.inventory),
	tourGroupNames: getTourGroupName(state.inventory),
	tourNames: getTourName(state.inventory),
	inventoryChange: getUpdatedInventory(
		state.inventory,
		state.referenceInventory,
	),
});

const mapDispatchToProps = (dispatch) => ({
	addInventory: (tourId, data) => dispatch(addInventory(tourId, data)),
	clearInventory: () => dispatch(clearInventory()),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditInventory),
);
