import { Container, ContainerItem, TextBlock } from '@headout/aer';
import React from 'react';
import { getBackendAPI, postData } from '../utils';
import { useHistory } from 'react-router-dom';

const logoutUser = () => {
	const logoutAPI = `${getBackendAPI().CALIPSO}/v3/supply-partner/logout`;

	postData(logoutAPI, {})
		.then(() => {
			window.location.reload();
		})
		.catch((e) => {
			alert(`Some error Occurred ${e}`);
		});
};

export const LogoBar = () => {
	let history = useHistory();
	return (
		<>
			<Container tailwind=' mt-4 mb-4 items-center justify-between mobile-padding'>
				<ContainerItem>
					<a
						onClick={() => {
							history.push('/dashboard');
						}}
					>
						<img
							src='https://cdn-imgix-open.headout.com/logo/svg/headout_logo_new.svg?w=300&h=50&fit=fill'
							style={{ height: 30 }}
						/>
					</a>
				</ContainerItem>

				<ContainerItem tailwind=' ml-2 justify-center '>
					<TextBlock
						color='black'
						family='content'
						size={2}
						weight='bold'
						tailwind='text-center w-full leading-none cursor-pointer'
						onClick={() => {
							logoutUser(history);
						}}
					>
						Logout
					</TextBlock>
				</ContainerItem>
			</Container>
		</>
	);
};
