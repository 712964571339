import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import { Button, ContainerItem, Input, TextBlock,Container } from '@headout/aer';
import { addInventory, revertInventoryClosedStatus, setInventoryClosedStatus, updateInventory } from '../redux/thunks';
import { connect, useDispatch } from 'react-redux';
import { AVAILABILITY } from '../constants';
import ReactTooltip from 'react-tooltip';
import {
	useToolbarStyles,
	useStyles,
	getComparator,
	stableSort,
} from './common/tableUtils';
import { getUpdatedInventory } from '../redux/stateUtils';
import { checkInventoryIsModified } from '../utils';

// Common for all components
let filteredInventoryList = [];

export function ToursTable({
	inventory,
	updateInventory,
	revertInventoryClosedStatus,
	selectedTime,selectedTourGroup,
	selectedTour,
	selectedClosedStatus,
	inventoryDelta,
}) {
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('startDate');
	const [page, setPage] = React.useState(0);
	const [dense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);
	const rows = inventory;

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const basedOnTime = (inventory) => {
		if (selectedTime === null) return true;
		return selectedTime === inventory.startTime;
	};

	const basedOnTourGroupName = (inventory) => {
		if (selectedTourGroup === null) return true;
		return selectedTourGroup === inventory.tourGroupName;
	};

	const basedOnTourName = (inventory) => {
		if (selectedTour === null) return true;
		return selectedTour === inventory.tourName;
	};

	const basedOnStatus = (inventory) => {
		if (selectedClosedStatus === null) return true;
		return selectedClosedStatus === inventory.closed;
	};


	const changedInventory = inventoryDelta.map((inventory) => inventory.initial);
	filteredInventoryList = stableSort(rows, getComparator(order, orderBy))
		.filter(basedOnTime)
		.filter(basedOnTourGroupName)
		.filter(basedOnTourName)
		.filter(basedOnStatus);
	return (
		<div className={classes.root}>
			<ContainerItem tailwind='mb-2'>
				<Paper className={classes.paper}>
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby='tableTitle'
							size={dense ? 'small' : 'medium'}
							aria-label='enhanced table'
						>
							<EnhancedTableHead
								classes={classes}
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>
							<TableBody>
								{filteredInventoryList
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const isModified = checkInventoryIsModified(changedInventory, row);
										const inventoryEditDisabled = row.availability === AVAILABILITY.UNLIMITED ||
											row.remaining === 9999;

										const remainingSeats = row.availability === AVAILABILITY.UNLIMITED ||
										row.remaining === 9999
											? 'UNL'
											: row.remaining;
										return (
											<TableRow
												hover
												onClick={(event) => {}}
												role='checkbox'
												tabIndex={-1}
												key={index}
												style={isModified ? { background: '#ffdbe2' } : null}
											>
												<TableCell>{row.startDate}</TableCell>
												<TableCell>{row.startTime}</TableCell>
												<TableCell>{row.tourGroupName}</TableCell>
												<TableCell>{row.tourName}</TableCell>
												<TableCell>
													<Switch
														checked={!row.closed}
														onChange={() => {
															revertInventoryClosedStatus(row.inventoryIndex);
														}}
													/>
													{!row.closed ? 'Open' : 'Closed'}
												</TableCell>
												<TableCell>
													<Input
														color='teal'
														handleReturnFun={null}
														isError={false}
														onIconClick={null}
														placeholder='Enter inventory'
														handleChange={(value) => {
															updateInventory(row.inventoryIndex, value);
														}}
														handleBlur={(value) => {
															if (!value || isNaN(value) || value < 0) {
																updateInventory(row.inventoryIndex, 0);
															}
														}}
														style={{ width: 80 }}
														value={remainingSeats}
														disabled={inventoryEditDisabled}
													/>
												</TableCell>
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[20, 50, 100]}
						component='div'
						count={rows.length}
						labelRowsPerPage={'Items per page'}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</ContainerItem>
		</div>
	);
}

const mapStateToProps = (state) => ({
	inventory: state.inventory.map((inventoryItem, index) => ({
		...inventoryItem,
		inventoryIndex: index,
	})),
	inventoryDelta: getUpdatedInventory(state.inventory, state.referenceInventory)
		.inventoryDiff,
});

const mapDispatchToProps = (dispatch) => ({
	addInventory: (tourId, data) => dispatch(addInventory(tourId, data)),
	revertInventoryClosedStatus: (index) => dispatch(revertInventoryClosedStatus(index)),
	updateInventory: (index, count) => dispatch(updateInventory(index, count)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToursTable);

/*
		Header and toolbar components for table
*/

const headCells = [
	{ id: 'startDate', numeric: true, disablePadding: false, label: 'Date' },
	{ id: 'startTime', numeric: true, disablePadding: false, label: 'Time' },
	{
		id: 'tourGroupName',
		numeric: false,
		disablePadding: false,
		label: 'Experience Name as on Headout',
	},
	{
		id: 'tourName',
		numeric: true,
		disablePadding: false,
		label: 'Options',
	},
	{ id: 'closed', numeric: false, disablePadding: false, label: 'Status' },
	{
		id: 'remaining',
		numeric: false,
		disablePadding: false,
		label: 'Seats',
	},
];


function RemainingSeats(){
	const [seats, updateSeats] = useState(0);
	const dispatch = useDispatch()
	const updateInventorySeats = (index,count) => dispatch(updateInventory(index,count));
	const applyChanges = ()=>{
		filteredInventoryList.forEach((inventory)=>{
			updateInventorySeats(inventory.inventoryIndex, seats)
		})
	}
	const handleInvalidSeats = (value)=>{
		if (!value || isNaN(value) || value < 0) {
			updateSeats(0)
			return;
		}
		updateSeats(value)
	}
	const inputComponent = (
		<Input
			color='teal'
			handleReturnFun={null}
			isError={false}
			onIconClick={null}
			handleBlur={handleInvalidSeats}
			handleChange={updateSeats}
			style={{width: 100}}
			placeholder='Seats'
			tailwind='mr-2 pt-1 pb-1'
			value={seats}
		/>
);

	return (
		<div>
			<a data-tip data-for='seats'  > Bulk Edit </a>
			<ReactTooltip id='seats'  place="bottom" type="light" effect="solid" delayHide={50}>
				<Container>
						<ContainerItem>
							{inputComponent}
						</ContainerItem>
					<ContainerItem>
						<Button
							style={{
								marginBottom: 0,
								maxWidth: 380,
							}}
							tailwind='ml-2 pt-1 pb-1'
							handleClick={applyChanges}
						>
							<TextBlock
								color='white'
								family='content'
								style={{ marginBottom: 0 }}
								weight='bold'
								tailwind='text-center w-full m'
							>
								Apply
							</TextBlock>
						</Button>
					</ContainerItem>
				</Container>

			</ReactTooltip>
		</div>
	)
}


function UpdateStatus(){
	const [closed, updateClosed] = useState(false);
	const dispatch = useDispatch()
	const updateInventoryStatus = (index,status) => dispatch(setInventoryClosedStatus(index,status));
	const applyChanges = ()=>{
		filteredInventoryList.forEach((inventory)=>{
			updateInventoryStatus(inventory.inventoryIndex, closed)
		})
	}

	const switchComponent = (<div>
	<Switch
		checked={!closed}
		onChange={(e) => {
			updateClosed(!closed);
		}}
	/>
		{!closed? 'Open': 'Closed'}
</div>);

	return (
		<div>
			<a data-tip data-for='status' > Bulk Edit </a>
			<ReactTooltip id='status'  place="bottom" type="light" effect="solid" delayHide={50}>
				<Container align='center'>
					<ContainerItem>
						{switchComponent}
					</ContainerItem>
					<ContainerItem>
						<Button
							tailwind='ml-2 pt-1 pb-1'
							handleClick={applyChanges}
						>
							<TextBlock
								color='white'
								family='content'
								style={{ marginBottom: 0, width: 100 }}
								weight='bold'
								tailwind='text-center w-full'
							>
								Apply
							</TextBlock>
						</Button>
					</ContainerItem>
				</Container>

			</ReactTooltip>
		</div>
	)
}

function renderTooltip(id){
	if(id !== 'closed' && id !== 'remaining') return null
	return id==='closed' ? <UpdateStatus/> : (<RemainingSeats/>)
}


function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
						{renderTooltip( headCell.id )}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const { numSelected } = props;
	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color='inherit' variant='subtitle1'>
					{numSelected} tours Selected
				</Typography>
			) : (
				<Typography className={classes.title} variant='h6' id='tableTitle'>
					Inventory List
				</Typography>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};
