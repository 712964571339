export const getTimeSlots = (inventories) => {
	let timeSlots = [];
	inventories.forEach((inventory) => {
		const { startTime } = inventory;
		if (!timeSlots.includes(startTime)) {
			timeSlots.push(startTime);
		}
	});
	return timeSlots.map((value) => ({ label: value, value }));
};

export const getTourGroupName = (inventories) => {
	let tourGroupNames = [];
	inventories.forEach((inventory) => {
		const { tourGroupName } = inventory;
		if (!tourGroupNames.includes(tourGroupName)) {
			tourGroupNames.push(tourGroupName);
		}
	});
	return tourGroupNames.map((value) => ({ label: value, value }));
};

export const getTourName = (inventories) => {
	let tourNames = [];
	inventories.forEach((inventory) => {
		const { tourName } = inventory;
		if (!tourNames.includes(tourName)) {
			tourNames.push(tourName);
		}
	});

	return tourNames.map((value) => ({ label: value, value }));
};

export const getUpdatedInventory = (currentInventories, initialInventories) => {
	const newInventory = currentInventories.filter((inventory, index) => {
		return (
			JSON.stringify(inventory) !== JSON.stringify(initialInventories[index])
		);
	});

	let inventoryDiff = [];
	// Computing the delta
	currentInventories.forEach((inventory, index) => {
		if (JSON.stringify(inventory) !== JSON.stringify(initialInventories[index])) {
			inventoryDiff.push({
				initial: initialInventories[index],
				current: inventory,
			});
		}
	});

	return { newInventory, inventoryDiff };
};
