import React, { Component } from 'react';
import { Button, Container, ContainerItem, TextBlock } from '@headout/aer';
import ToursTable from '../components/TourListTable';
import { getBackendAPI, getData, getFrontendHost } from '../utils';
import { withRouter } from 'react-router-dom';
import { LogoBar } from '../components/LogoBar';

function createData(nameInVendorSite, tourId, tourGroupName, tourName, link) {
	return { nameInVendorSite, tourId, tourGroupName, tourName, link };
}

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tours: [],
			selectedTourID: [],
			loading: true,
		};
	}
	componentDidMount() {
		const vendorTourListAPI = `${
			getBackendAPI().ARIES
		}/vendor-tour/list-for-user`;
		getData(vendorTourListAPI)
			.then((data) => {
				this.rowTransformAndSaveToState(data);
			})
			.catch((e) => {
				alert(`Some error Occurred :- ${e}`);
			});
	}

	rowTransformAndSaveToState(data) {
		const automatedInventory = (tour)=> !tour.inventoryAutomated;
		const filteredInventory = data.filter(automatedInventory);
		const transFormedData = filteredInventory.map((tour) => {
			const {
				nameInVendorSite,
				tourId,
				tourgroupName,
				tourName,
				touGroupId
			} = tour;

			const link = `${getFrontendHost()}/tour/${touGroupId}`;

			return createData(nameInVendorSite, tourId, tourgroupName, tourName, link);
		});

		this.setState({
			tours: transFormedData,
			automatedTours: data.length - filteredInventory.length,
			loading: false,
		});
	}

	render() {
		return this.renderMethod();
	}

	renderMethod() {
		return (
			<Container
				color='black'
				direction='col'
				justify='center'
				type='flex'
				weight='normal'
				tailwind={'content-container '}
			>
				<LogoBar />
				<ContainerItem tailwind='mb-2'>
					<TextBlock
						family='content'
						style={{ marginBottom: 0, width: 100 }}
						tailwind=' w-full '
					>
						{/*{this.state.automatedTours}*/}
						Your tour will not be listed  on this dashboard If you are controlling Headout's inventory via your API partner. <br/>Please use your API Partner to make changes to your inventory.
					</TextBlock>
				</ContainerItem>

				<ContainerItem tailwind='mb-2'>
					<ToursTable
						rows={this.state.tours}
						setSelectedTourId={this.setSelectedTourId}
					/>
				</ContainerItem>
			</Container>
		);
	}
}

export default withRouter(Dashboard);
