export const BACKEND_HOSTS = {
	DEV: {
		CALIPSO: 'https://www.localhost.com/api',
		ARIES: 'https://aries.localhost.com/apis',
	},
	TEST: {
		CALIPSO: 'https://www.test-headout.com/api',
		ARIES: 'https://aries.test-headout.com/apis',
	},
	STAGE: {
		CALIPSO: 'https://www.stage-headout.com/api',
		ARIES: 'https://aries.stage-headout.com/apis',
	},
	PROD: {
		CALIPSO: 'https://www.headout.com/api',
		ARIES: 'https://aries.headout.com/apis',
	},
};

export const FRONTEND_HOST = {
	DEV: {
		DEIMOS: 'https://www.localhost.com',
	},
	TEST: {
		DEIMOS: 'https://www.test-headout.com',
	},
	STAGE: {
		DEIMOS: 'https://www.stage-headout.com',
	},
	PROD: {
		DEIMOS: 'https://www.headout.com',
	},
};

export const AVAILABILITY = {
	UNLIMITED: 'UNLIMITED',
	LIMITED: 'LIMITED',
	CLOSED: 'CLOSED',
};

export const INVENTORY_EDIT_STATE = {
	EDIT: 'EDIT',
	REVIEW: 'REVIEW',
};

// In backend we don't have option to preserve state for unlimited inventories.
// For that purpose, we are keeping unlimited seats to this no for consistency.
export const UNLIMITED_SEATS = 9999;
